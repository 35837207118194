<h3 mat-dialog-title>{{'Job: '+ data.jobId +' '+data.jobName}}</h3>
<form [formGroup] = "fileForm" (ngSubmit)="uploadFile()">
    <mat-dialog-content class="form-group">
        <label for="file">Please upload your resume </label>
        <input
            formControlName="file"
            type="file"
            id="file"
            class="form-control"
            (change)="onFileChange($event)" required>
        <div  *ngIf="f.file.touched && f.file.invalid" >
            <div  style="color: red;" *ngIf="f.file.errors.required">File is required.</div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button cdkFocusInitial type="submit" [disabled]="fileForm.invalid">Submit</button>
    </mat-dialog-actions>
</form>