<div class="container " >
    <div class="full-bg-img ">
      
            <div class="fade-in">
               
            </div>
            <div class="slideIn1">
                <h3>Right People, Right Solution</h3>
            </div>
            <div class="fade-in-2"></div>
            <div class="slideIn2">
                <h3>  Itech Communications Inc. is an established IT Consulting Company since 2011, it provides services to both Public and Private sector clients on complex engagements. We provide not only the IT recruiting services to find right people for you, but also provide the advanced technique solution to grow your business success.</h3>
            </div>
        


        
    </div>
    <div class="footer">
        <span>Copyright ©2020. All rights reserved.</span>
    </div> 

   
 
</div>
