import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Info} from '../model/info';
import { InfoService } from '../service/info.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['../app.component.scss','./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name: FormControl;
  email: FormControl; 
  phone: FormControl;
  message: FormControl;

  info = new Info();
  formSubmitted = false;
  constructor(private infoService : InfoService) { }
  myForm: FormGroup;
  ngOnInit(): void {
    this.name = new FormControl('');
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.phone = new FormControl('');
    this.message = new FormControl('',[Validators.required]);

    this.myForm = new FormGroup({
      'name': this.name,
      'email': this.email,
      'phone': this.phone,
      'message': this.message
    });
  }


  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'An email address is required';
    }
   
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  getMessageErrorMessage() {
    if (this.message.hasError('required')) {
      return 'You must enter a message';
    }
   
    return '';
  }
  sendMessage(info : Info){
    this.infoService.sendMessage(info);
    this.formSubmitted = true;
  }
}
