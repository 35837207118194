<div>
  <table width="100%">
    <tr>
      <td width="30%" align="left">
          <img  src="assets/img/comlogo2.png">
      </td>
      <td width="70%" align="right">
          
          <mat-toolbar  color="primary" >
              <span class="spacer"></span>
              <a mat-button routerLink="home" routerLinkActive="active" class="menu-font">Home</a>
              <a mat-button routerLink="services" routerLinkActive="active" class="menu-font">Services</a>
              <a mat-button routerLink="contact" routerLinkActive="active" class="menu-font">Contact</a>  
              <a mat-button routerLink="jobposting" routerLinkActive="active" class="menu-font">Job Posting</a>  
              
          </mat-toolbar>
      </td>
    </tr>
      
  </table>


</div>

