<div class="center bottom-border">
    <h2>JOB LIST</h2>
</div>
<div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Job Filter">
    </mat-form-field>
</div>
<div mat-elevation-z8>
    <div class="example-container ">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
      
          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
      
          <!-- Client Column -->
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
            <td mat-cell *matCellDef="let element"> {{element.client}} </td>
          </ng-container>
      
          <!-- Deadline Column -->
          <ng-container matColumnDef="deadline">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Deadline </th>
            <td mat-cell *matCellDef="let element"> {{element.deadline}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="primary" (click)="element.isExpanded = !element.isExpanded">View
                Details</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      
              <div class="row student-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                <mat-list>
                  <button mat-raised-button color="primary" (click)="openDialog(element.id, element.title)">Apply this
                    position</button>
                  <div  *ngIf="element.details.role">
                    <h3>Role:</h3>
                    <div mat-line>{{element.details.role}}</div>
                  </div>
                  <div *ngIf="element.details.musthave.length">
                    <h3 class="font-red">Must-haves:</h3>
                    <ul class="font-red">
                      <li class="font-red" *ngFor="let item of element.details.musthave">
                        {{item}}
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="element.details.assets.length">
                    <h3 class="font-red">Assets:</h3>
                    <ul class="font-red">
                      <li class="font-red" *ngFor="let item of element.details.assets">
                        {{item}}
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="element.details.note.length">
                    <h3 class="font-red">Note:</h3>
                    <ul class="font-red">
                      <li class="font-red" *ngFor="let item of element.details.note">
                        {{item}}
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="element.details.skillrequried.length">
                    <h3>Experience and Skill Set Requirements</h3>
                    <div *ngFor="let skill of element.details.skillrequried">
                      <h4 >{{skill.skillName + ' - '+ skill.skillWeight + '%'}}</h4>
                      <ul>
                        <li *ngFor="let item of skill.skillItems">
                          {{item}}
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </mat-list>
              </div>
      
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="student-element-row" [class.student-expanded-row]="element.isExpanded"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
          
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
      </div>
  <!--   <div class="footer" width="100%">
        <span>Copyright ©2020. All rights reserved.</span>
      </div>
      --> 

</div>


