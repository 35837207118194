export class Info {



      public name: string;
      public email: string;
      public phone: string;
      public message: string;

  
  }