
<div class="service">


    <div class="center bottom-border">
        <h2>SERVICE OFFERINGS</h2>
    </div>
    <div class="flex-container">
        <mat-card class="example-card">
            <mat-icon class="green-icon" > people</mat-icon>
            <mat-card-header>
                 <mat-card-title class="blue-color">IT Recruiting</mat-card-title>
    
            </mat-card-header>
    
             <mat-card-content >
                <p >
                    we have developed a bank of highly skilled, certified and trained Information Technology professionals crossing mulitple areas. We can quickly fill permanent and temporary assignments with qualified candidates.
                </p>
            </mat-card-content>
            
        </mat-card>
        <mat-card class="example-card">
            <mat-icon class="green-icon" > domain</mat-icon>
            <mat-card-header>
                <mat-card-title class="blue-color">Enterprise Applications</mat-card-title>

            </mat-card-header>

            <mat-card-content >
                <p >
                    We can help you design and implement applications that work efficiently while improving operations and performance across the enterprise. With new emgerging Technologies like Cloud, Big Data and AI, we are dedicated to helping organizations tackle their most complex challenges with enterprise applications.
                </p>
            </mat-card-content>
        
        </mat-card>
        <mat-card class="example-card">
            <mat-icon class="green-icon" > insert_chart</mat-icon>
            <mat-card-header>
                <mat-card-title class="blue-color">Data Analysis & Business Intelligence</mat-card-title>

            </mat-card-header>

            <mat-card-content >
                <p >
                    Implementing a business intelligence (BI) solution can be a game changer for your organization by providing integrated insight into data from all corners of the business. we can provide analysis of information delivery scope and identify reporting/or BI analytic
                    requirements & identification of data sources for generating operational reports, BI Analytics, data mining reports. Further created dashboard for the management to forecast and make balanced business decisions.
                </p>
            </mat-card-content>

        </mat-card>
        
    
    </div>
    <div class="flex-container">
        <mat-card class="example-card">
            <mat-icon class="green-icon" > security</mat-icon>
            <mat-card-header>
                <mat-card-title class="blue-color">Security</mat-card-title>

            </mat-card-header>

            <mat-card-content >
                <p >
                    Protecting your business requires more than just crossing your fingers. It means thinking differently to safeguard your most valuable assets. we work to increase your resilience against cyber threats.Anytime. Anywhere. We create cybersecurity tailored to your specific business needs. We defend against cyberattacks with proactive, focused, industry-relevant threat intelligence to give you the confidence that comes from knowing your business is secure.
                </p>
            </mat-card-content>
        
        </mat-card>
        <mat-card class="example-card">
            <mat-icon class="green-icon" > cloud_upload</mat-icon>
            <mat-card-header>
                <mat-card-title class="blue-color">Cloud</mat-card-title>

            </mat-card-header>

            <mat-card-content >
                <p >
                    Today, more than ever, companies need to operate and compete at an unprecedented speed and scale as industries are reshaping beneath them. Could is the solution. We help transform your applications and migrate them to a cloud environment. You will benefit from the world’s most reliable and stable data centers, and only pay for what you need.
                </p>
            </mat-card-content>
    
        </mat-card>
        <mat-card class="example-card">
            <mat-icon class="green-icon" > android</mat-icon>
            <mat-card-header>
                <mat-card-title class="blue-color">Artificial Intelligence</mat-card-title>

            </mat-card-header>
    
            <mat-card-content >
                <p >
                    Artificial intelligence consulting and development services that empower you to become a leader in your field, We use machine learning (ML) tools and algorithms to help companies develop AI-driven products and solutions. We have profound knowledge and experience in designing, implementing, and integrating artificial intelligence solutions into the customer’s business environment.
                </p>
            </mat-card-content>
    
        </mat-card>
    </div>
   
</div>
<div class="footer" width="100%">
    <span>Copyright ©2020. All rights reserved.</span>
</div> 
