<div class="contact">


    <div class="center bottom-border">
        <h2>CONTACT US</h2>
    </div>
    <div class="flex-container">
        <mat-card class="example-card">
            
            <mat-card-header>
               
                 <mat-card-title class="font-bold" >Our Contact Information</mat-card-title>
    
            </mat-card-header>
    
             <mat-card-content >
                <mat-icon > call</mat-icon>
               <span style="font-size: 1.5em">  4168931036</span>
            </mat-card-content>
            <mat-card-content >
                <mat-icon > email</mat-icon>
               <span style="font-size: 1.5em">  info@itechcommunications.ca</span>
            </mat-card-content>
            
        </mat-card>
        <mat-card class="example-card">
            
            <mat-card-header class="card-header-center">
                <mat-card-title class="font-bold">Send Us A Message</mat-card-title>

            </mat-card-header>

            <mat-card-content >
                 <div *ngIf="formSubmitted">
                    <p style="color:green;font-size: 1.5em"><strong> Message has been sent successfully </strong></p>
                   
                </div>
                <form [formGroup]="myForm" (ngSubmit)="sendMessage(myForm.value)">
                    <p>
                        <mat-form-field appearance="fill"   style="width: 250px;">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="name">

                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="fill" style="width: 250px;">
                            <mat-label>Enter your email</mat-label>
                            <input matInput placeholder="pat@example.com" formControlName="email" required>
                            <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
                            <mat-icon matPrefix> email</mat-icon>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="fill" style="width: 250px;">
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="phone">
                        <mat-icon matPrefix>call</mat-icon>

                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="fill" style="width: 500px;">
                            <mat-label>Message</mat-label>
                            <textarea matInput rows="10" cols="200" formControlName="message" required></textarea>
                            <mat-error *ngIf="message.invalid">{{getMessageErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </p>
                    <button type="submit" mat-raised-button color="primary" class="my-button" [disabled]="myForm.invalid">Send</button>
                </form>
            </mat-card-content>
        
        </mat-card>
    </div>
    
</div>
<div class="footer" width="100%">
    <span>Copyright ©2020. All rights reserved.</span>
</div> 