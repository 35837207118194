import { AfterViewInit, Component, ViewChild,Inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { Job} from '../model/job';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validator, Validators } from '@angular/forms';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['../app.component.scss','./jobs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class JobsComponent implements AfterViewInit  {
  displayedColumns: string[] = ['id', 'title', 'client', 'deadline', 'action'];
  dataSource = new MatTableDataSource<Job>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public dialog: MatDialog){

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  openDialog(jobId: string, jobName : string): void{
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {jobId: jobId, jobName: jobName}
    });
  }
}
export interface DialogData {
  jobId: string;
  jobName: string;
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {
  fileForm = new FormGroup({
      file: new FormControl('',[Validators.required]),
      fileSource: new FormControl('',[Validators.required])
  });
  fileToUpload: File = null;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
    
  get f(){
    return this.fileForm.controls;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileForm.patchValue({
        fileSource: file
      });
    }
  }

  getFileErrorMessage() {
    if (this.fileForm.controls.file.hasError('required')) {
      return 'An file is required';
    }
   
    return '';
  }
  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.fileForm.get('fileSource').value);
    this.dialogRef.close();
   /*
    this.http.post('http://localhost:8001/upload.php', formData)
      .subscribe(res => {
        console.log(res);
        alert('Uploaded Successfully.');
      })*/
  }
}
const ELEMENT_DATA: Job[] = [
 { 
    id: 'RQ00120', title: 'Task-Based I&IT Consultant - Level 2', client: 'Ministry of Transportation' ,deadline :'2020-12-09' , isExpanded : false,
    details: { 
           role: 'OPS requires a resource to come in for the initial planning and implementation phase of the implementation of the fraud detection system. This resource will assist with the selection of the solution and consider various options including vendor selections for off the shelf, building in house, etc. Resource will identify what data is currently available and what OPS needs. They are looking for a resource with strong expertise in this area.',
           musthave: [
             'Strong and recent experience in end to end implementation and operations of a fraud detections',
             'Resource needs to understand what it takes to put a fraud detection solution into production',
             'Background in fraud detection and data science a MUST',
             'Ideal background in open source fraud solutions, claims based fraud detection and commercial product experience',
             'Experience with Python a strong asset'
    
           ],
           assets:[

           ],
           note:[

           ],
           skillrequried:[
              {
                skillName: 'Fraud Detection',
                skillWeight: 30,
                skillYear: '',
                skillItems:[
                  'Hands-on experience in dealing with financial transactions including fraud detection techniques (ideally in a pay per claim model)',
                  'Hands-on experience in designing/building/tuning fraud detection systems using custom build and/or commercial products employing various methodologies (rule-based, predictive models, deep learning)',
                  'Expert proficiency in analytics, data engineering and data science with focus on building and tuning predictive models using open-source and/or commercial tools',

                ]
             },
             {
                skillName: 'Solution Designing & Implementation',
                skillWeight: 20,
                skillYear: '',
                skillItems:[
                  'Have solution design experience for Fraud Detection platforms (infrastructure and software) including option analysis',
                  'Hands-on experience in implementing and operating Fraud Detection solutions in a production environment'

                ]
             },
             {
              skillName: 'Business/Data analysis',
              skillWeight: 15,
              skillYear: '',
              skillItems:[
                'Experience working with business people as to understand business processes, policies and needs and create precise business requirements',
                'Experience in dealing with large and diverse data assets, and in identifying the suitability of specific data sets to build predictive Fraud Detection model',
                'Experience working with both technical and non-technical people as to forge collaboration and common understanding'

              ]
             },
             {
              skillName: 'General',
              skillWeight: 5,
              skillYear: '',
              skillItems:[
                'Critical thinking, analytical and problem-solving skills',
                'Excellent verbal, written and presentation skills.',
                'Strong consulting skills to engage with all stakeholders.',
                'Proven track record for building strong working relationships',
                'Excellent customer service skills, including tact and diplomacy to ensure client needs are managed effectively',
                'A motivated, flexible, creative team player with perseverance, excellent multi-tasking abilities and a proven track record for meeting strict deadlines'

              ]
             }
           ]


    }
  },
  { 
    id: 'RQ00255', title: 'Solutions Designer - Level 3', client: 'Ministry of Education' ,deadline :'2020-12-14' , isExpanded : false,
    details: { 
           role: 'We are looking for a very experienced ETL (Informatica) developer with BI experience. This person will be the sole developer on the project, so must be very skilled.',
           musthave: [
             'ETL - Informatica',
             'BI - / PowerBI/SSRS/ Cognos/ Informatica',
             'Oracle',
             'UAT'
           ],
           assets:[
            'Public sector'
           ],
           note:[

           ],
           skillrequried:[
              {
                skillName: 'Technical Experience',
                skillWeight: 30,
                skillYear: '',
                skillItems:[
                  'Demonstrates experience designing, developing, and implementing Informatica ETL solutions',
                  'Demonstrates development and solution design experience in an environment where Oracle, Informatica and Cognos are used together for data warehousing and business intelligence',
                  'Demonstrates experience developing the Application Code package using MS Visual Source Safe (VSS), Team Foundation Server (TFS) and documenting code changes',
                  'Demonstrates working experience in debugging mappings, transformations, mapplets and source qualifier ETL components',
                  'Demonstrates development and solution design experience with Oracle 11.x and 12.x databases',
                  'Demonstrates experience constructing complex query statements and enhancing performance of SQL Queries',
                  'Demonstrates experience designing and developing Unix shell scripts',
                  'Demonstrates Experience using PowerDesigner to review data models, as well as make changes and generate DDL scripts',
                  'Demonstrate experience designing, developing and implementing Reports, Dashboards and other Business Intelligence solutions using Cognos 10 or higher and Microsoft Power BI',
                  'Experience with data transfer technologies such as Microsoft MQ, SSIS, SSRS, SSAS',
                  'Experience in leading development and modelling of applications, specifically hierarchical data model applications'

                ]
             },
             {
                skillName: 'Core Skills',
                skillWeight: 20,
                skillYear: '',
                skillItems:[
                  'Demonstrates development and solution design experience with technology, data, databases, statistics, applications, and networking techniques, tools and practices that enables the candidate to lead the design and development of business intelligence projects',
                  'Demonstrates experience in working with and analyzing large and diverse data sets, data visualization, KPI development and data profiling to identify relationships and themes',
                  'Demonstrates experience manipulating and analyzing complex, high-volume data from structured and unstructured sources',
                  'Demonstrates experience creating detailed documentation for the tools that are developed, such as business requirements, system requirements and technical requirements',
                  'Demonstrated experience developing star schema multi-dimensional models and documenting detailed design models',
                  'Demonstrates experience with advanced data visualization tools and techniques, designing high quality interfaces to present information in a meaningful way',
                  'Troubleshooting of production issues and creating fixes for any unplanned deployments',
                  'Defect investigation, resolution and assignment to team members using defect/issue',
                  'management tools, e. g. HP ALM or HP Service ManagerDemonstrated experience in coordinating and planning for the modernization of large, complex multi-platform, multitier information technology systems.Proven experience using a variety of methodologies to carry-out the software development lifecycle (e.g., Agile, waterfall, etc.)'

                ]
             },
             {
              skillName: 'Project Experience and Techniques',
              skillWeight: 10,
              skillYear: '',
              skillItems:[
                'Demonstrates development and design experience with SDLC processes, Agile and Waterfall methodologies',
                'Demonstrates experience conducting system testing and unit testing, and conducting SIT, SAT and UAT Testing',
                'Demonstrates experience communicating regarding day-to-day tasks and issue tracking, reporting and facilitating resolution of issues and risks for all project activities',
                'Demonstrates experience gathering and consolidating input into changes required to business and/or system requirements, change requests, project artefacts, as well as leading requirement gathering sessions'

              ]
             },
             {
              skillName: 'General Skills',
              skillWeight: 5,
              skillYear: '',
              skillItems:[
                'Demonstrates experience problem solving and decision making',
                'Demonstrates experience communicating clearly in both written and verbal formats',
                'Demonstrates experience working with and leading multiple teams representing various areas in delivering the project',
                'Demonstrates ability to work collaboratively, with the ability to coordinate multiple projects with competing priorities and a track record for meeting strict deadlines',
                'Demonstrates experience working collaboratively with other team members and/or groups and leading a team to ensure optimal solution integration',
                'Demonstrates experience leading a team and with providing technical advice and guidance',
                'Demonstrates experience working with both the business users and IT development teams to ensure business requirements are properly reflected in the system design and technical specifications'

              ]
             },
             {
              skillName: 'Organization Experience',
              skillWeight: 5,
              skillYear: '',
              skillItems:[
                'Previous public sector experience in an organization of equivalent size and complexity to the Ontario Public Service'
                
              ]
             }
           ]


    }
  },
  { 
    id: 'RQ00376', title: 'Technology Architect - Level 3', client: 'Ministry of Health' ,deadline :'2020-12-14' , isExpanded : false,
    details: { 
           role: '',
           musthave: [
             
           ],
           assets:[
            'Public Sector experience'
           ],
           note:[
            'Assignment Type: This position is currently listed as "Hybrid" due to COVID-19 related WFH direction. Once HSC staff are required to return to the office, the resource under this request will be required to work onsite as well.',
            'Extension/Amendment Attestation: Extension(s) only allowed using unused days/funds left on contract. No additional funds will be added beyond maximum contract value'
           ],
           skillrequried:[
              {
                skillName: 'Technical Skills',
                skillWeight: 30,
                skillYear: '10+ years of experience with:',
                skillItems:[
                  'Solutions architecture with emphasis on information and technology architecture',
                  'Preparing overall archtecture solutions, conducting walkthroughs, reviews and assessments, analyzing technical and business impact, product integration, enhancements, infrastructure capacity, development and deployment, data distribution, integration, operational stabilization and access',
                  'Experience with large, complex, multi-tier web and cloud based, business critical applications, involving personal health information',
                  'Knowledge and experience with the following Amazon Web Services (AWS) technologies – Amazon Connect, Amazon Pinpoint, Amazon DynamoDB, Amazon Kenisis Datastreams, Amazon S3, Amazon Polly, AWS Lamda and Amazon Cloudwatch',
                  'Knowledge and experience with the following technologies: Salesforce.com, Sales Cloud, Service Cloud, Lightning Component Framework, Design System, WebServices, SOAP, REST, API, Javascript, JqueryUI, Oracle, MS SQL, MySQL, DB2, Actuate Reports, Cognos, Informatica, SSIS, .NET, Agile, BasicGov, Vlocity, FinancialForce, MyFax, Marketo, J2EE, SQL, PHP, PL/SQL, C++, VBA, HTML, ESB, Python and Node.JS. ',
                  'Developing highly robust code frameworks in JS, Lightning, and Apex'

                ]
             },
             {
                skillName: 'Core Skills and Experience',
                skillWeight: 25,
                skillYear: '10+ years of experience with:',
                skillItems:[
                  'Architectural framework, artifacts and passing governance/checkpoint review processes',
                  'Developing architectural project plans and resources',
                  'Developing and maintaining architecture models and artefacts',
                  'Experience with all gating approval requirements for large scale projects (including scope/contextual, business model/conceptual, logical and physical)',
                  'Knowledge of Contact Center Technology such as Call Routing, Interactive Voice Response, Workforce Management, etc',
                  'Experience with the following: solution architecture and design; enterprise and technical architecture; software configuration, development and deployment; vendor management and program management, Agile software development principles',
                  'Knowledge and understanding of Information Management principles, concepts, policies and practices',
                  'Leadership experience in the development and implementation of technical architectures at the specified experience level',
                  'Extensive experience with at least three different platforms, operating systems, environments, database technologies, and communications protocols',
                  'Experience with middleware and gateways',
                  'Knowledge of performance considerations in different environments',
                  'Experience in structured methodologies for the design, development and implementation of applications',
                  'Extensive experience in systems analysis and design in large systems environments',
                  'Experience in the use of Computer Aided Software Engineering (CASE) tools and other software tools such as project management tools',
                  'Awareness of emerging technologies, trends and directions',
                  'Experience in developing enterprise architecture deliverables (e.g. models)',
                  'Leading and mentoring individuals for growth and project success.'

                ]
             },
             {
              skillName: 'Public Sector/Healthcare Experience',
              skillWeight: 5,
              skillYear:'',
              skillItems:[
                'Knowledge of OPS Enterprise Architecture artifacts (or similar), processes and practices, and ability to produce technical documentation that comply with industry standard practices',
                'Knowledge and experience with Health messaging standards and protocols, Personal Health Information Privacy legislation and related security considerations and controls',
                'In-depth knowledge of industry standard such as Project Management Institute (PMI) ',
                'Knowledge of OPS I&IT project management methodologies an asset',
                'Knowledge and experience with OPS or Broader Public-Sector health related projects',
                'Knowledge and understanding of Ministry policy and IT project approval processes and requirements',
                'Experience adopting and adhering to OPS Unified I&IT Project Methodology, OPS Enterprise Architecture and OPS Gating process, and OPS Standard Systems Development Methodologies would be an asset',
                'Experience with large complex IT Health-related projects',
                'Experience with GO-ITS Digital Health standards, and internal branch standards would be an asset.'

              ]
             },
             {
              skillName: 'General Skills',
              skillWeight: 10,
              skillYear:'',
              skillItems:[
                'Demonstrated strong leadership and people management skills',
                'Exceptional analytical, trouble-shooting, problem solving and decision-making skills',
                'Demonstrated strong interpersonal, verbal and written communication, and presentation skills',
                'Proven troubleshooting and critical thinking experience',
                'Demonstrated ability to apply strong listening skills to facilitate issue resolution',
                'Effective consulting skills to engage with all stakeholders with proven track record for building strong working relationships',
                'Strong interpersonal, facilitation and negotiation skills with ability to build rapport with stakeholders and drive negotiations to a successful outcome',
                'Excellent customer service skills, including tact and diplomacy to ensure client needs are managed effectively',
                'A motivated, flexible, detail-oriented and creative team player with perseverance, excellent organization and multi-tasking abilities, and a proven track record for meeting strict deadlines.'

              ]
             }
             
           ]


    }
  },
  { 
    id: 'RQ00372', title: 'Programmer/Developer - Level 3', client: 'Ministry of Solicitor General' ,deadline :'2020-12-14' , isExpanded : false,
    details: { 
           role: 'Ministry looking to redevelop an existing .NET solution. Current system is an online form that is used to capture criminal intelligence from police services. This consists of an online form that is saved back to online location (no data capturing). The ministry is now trying to provide a common form for all Ontario agencies. Currently some issues with the skeleton of the system so the ministry is switching to a more robust solution which is a proper .NET solution. This resource will be meeting with the business units to understand the business requirements and translating into the technical requirements then doing the bulk of the coding work.',
           musthave: [
            'Must have 10+ years of .NET development experience',
            'Must have C# programming language experience',
            'SQL and Oracle experience (current solution is Oracle DB for administering website)',
            'Experience with graphical UI design (for enhancing forms)',
            'Experience with online forms a strong asset',
            'Experience with application design or development within a law enforcement agency context a strong asset',
            'Demonstrated experience with participating in requirements solicitation',
            'Experience working with BAs to translate BRs',
            'Experience using PKI client authentication a strong asset',
            'NOT looking for an architect or consultant.. need RECENT .NET development experience'
           ],
           assets:[
           ],
           note:[
           ],
           skillrequried:[
              {
                skillName: '.Net Development',
                skillWeight: 20,
                skillYear: '',
                skillItems:[
                  'Experience in building applications using MS .NET elements such as Windows Communication Foundation, Windows Presentation Framework, MS Sync Framework and MS Web Services and Entity Framework',
                  'Experience coding and implementing applications using, C#.Net and ASP.Net, ASP.NET, MVC, ASP.NET Identity, C#, VB.NET',
                  'Experience with building applications using MS .NET elements such as Visual Studio, Team Foundation Server, and Enterprise Library'

                ]
             },
             {
                skillName: 'OOL / Frameworks / Foundations',
                skillWeight: 20,
                skillYear: '',
                skillItems:[
                  'Demonstrated experience with developing detailed programming specifications, user interface designs, prototypes, writing and generating code',
                  'Experience in GUI design.'

                ]
             },
             {
              skillName: 'Environment Technical Skills',
              skillWeight: 15,
              skillYear:'',
              skillItems:[
                'Experience with Windows 10, 2012, 2016 and 2019 environments.',
                'Experience with Apache/IIS webservers and reverse proxies.',
                'Demonstrated experience with JavaScript applications, including node.js environment.',
                'Experience with coding and implementing SQL procedures, scripts, and Object definitions for Oracle 12C or above.',
                'Experience with XML/XLST.',
                'Experience using PKI client authentication.'

              ]
             },
             {
              skillName: 'Business and Communication Skills',
              skillWeight: 10,
              skillYear:'',
              skillItems:[
                'Demonstrated experience capturing business requirements and translating them into functional solutions.',
                'Experience working alone as well as taking direction from development leads and project managers.',
                'Experience presenting solution proposals and communicating with clients and stakeholders.'
              ]
             },
             {
              skillName: 'Public Sector Experience and Law Enforcement Client Experience',
              skillWeight: 5,
              skillYear:'',
              skillItems:[
                'Knowledge of and experience with Public Sector as well as experience with law enforcement application development and support would be an asset.'
              ]
             }
             
           ]


    }
  },
  { 
    id: 'RQ00320', title: 'Business Analyst - Level 3', client: 'Ministry of Health' ,deadline :'2020-12-11' , isExpanded : false,
    details: { 
           role: '',
           musthave: [
             
           ],
           assets:[
            'Public Sector experience'
           ],
           note:[
            'Assignment Type: This position is currently listed as "Hybrid" due to COVID-19 related WFH direction. Once OPS staff are required to return to the office, the resource under this request will be required to work onsite as well. '
           ],
           skillrequried:[
              {
                skillName: 'IT Knowledge and Experience',
                skillWeight: 20,
                skillYear: '10+ years of experience in the following:',
                skillItems:[
                  'Demonstrated awareness of emerging IT trends and technologies',
                  'Highly proficient using MS Office products',
                  'Knowledge and experience with implementing cloud based technologies/solutions',
                  'Knowledge and experience with the following Amazon Web Services (AWS) technologies – Amazon Connect, Amazon Pinpoint, Amazon DynamoDB, Amazon Kenisis Datastreams, Amazon S3, Amazon Polly, AWS Lamda and Amazon Cloudwatch',
                  'Knowledge of Contact Center Technology such as Call Routing, Interactive Voice Response, Workforce Management, etc.',
                  'Experience in the following is an asset:',
                  'Knowledge of Salesforce (admin level preferred)',
                  'Experience using Salesforce or similar CRM Products to develop solutions',
                  'Hands on experience implementing Safesforce functionality',
                  'Hands on experience configuring Salesforce or similar CRM Product, including workflows, validation rules, and security controls'

                ]
             },
             {
                skillName: 'Business Analysis Skills',
                skillWeight: 35,
                skillYear: '10+ years of experience in the following:',
                skillItems:[
                  'Leading and conducting business analysis at varying levels of detail in order to assess client’s business problems/opportunities and documenting the business requirements in such a way that technology solutions can be determined',
                  'Creating procedures and processes to deploy changes in production environment to avoid or minimize impact to business operations',
                  'Authenticating changes suggested by development, provide analysis and suggestion to avoid impact to business operations',
                  'Substantiating analysis to business group to fix data issues and provide queries and scripts that help in debugging data issues',
                  'Regularly reviewing production logs, providing analysis & suggestions to implement in a proactive manner ',
                  'Providing a comprehensive range of I&IT business analysis lead services for diverse business and technology needs, including development and maintenance of business relationships and providing ongoing I&IT advice for improvements to/options for business development',
                  'Preparing and advising on business analysis policies, processes, best practices and standards to promote a comprehensive and consistent business analysis practice within the organization',
                  'Conduct ongoing business analysis activities and analysis within all phases of the project life cycle, including initial technical requirements, detailed plan of business analysis activity, business modeling, analysis of business transformation, new business development and acquired solutions, continual refinement of business requirements and identification of significant changes in requirement documentation, and maintenance of approved business requirement',
                  'Leading subject matter experts and stakeholder working groups, conduct business analysis to assess client’s current business problems, defects, improvements and minor enhancements',
                  'Documenting the business requirements in such a way that technology solutions can be determined',
                  'Promoting the appropriate application of I&IT to business problems, and undertake comprehensive business needs assessments and analysis of complex business requirements for IT business solutions',
                  'Updating business processes and user documentation for changes related to supported solution',
                  'Applying re-engineering concepts to promote business improvements through alternative, cost effective service delivery approaches',
                  'Developing strategies, preparing business cases and cost- benefit analysis, and conducting feasibility studies for business I&IT initiatives',
                  'Providing impact analysis and requirement/specification creation for supported solutions due to solution upgrades or any new requirements',
                  'Conducting business modeling, use case modeling, conceptual and logical data modeling, process and data flow modeling, use case surveys, business rules definition, and non-functional requirements definitions for assigned projects',
                  'Conducting gap analysis of vendor release upgrades to identify changes needed for business processes and best practice, and for solutions including configuration, reports and integration components',
                  'Monitoring progress, resolving problems and reporting regularly to I&IT management and clients’ decision makers',
                  'Developing performance measures for business analysis evaluation and conducting follow-up',
                  'Experience with dispensed drug data and/or immunization data.'

                ]
             },
             {
              skillName: 'Public Sector/Healthcare Experience',
              skillWeight: 5,
              skillYear:'',
              skillItems:[
                '5+ years of experience working with federal/provincial/broader public sector healthcare providers',
                'Knowledge of Public Sector Enterprise Architecture artifacts (or similar), processes and practices an asset',
                'Ability to produce technical documentation that comply with industry standard practices',
                'In-depth knowledge of industry standard such as Project Management Institute (PMI)',
                'Knowledge of Public Sector I&IT project management methodologies an asset',
                'Knowledge and experience with Public Sector Health related projects an asset',
                'Knowledge and understanding of Ministry policy and IT project approval processes and requirements an asset',
                'Experience adopting and adhering to Public Sector Unified I&IT Project Methodology, Public Sector Architecture and Gating process, and Public Sector Standard Systems Development Methodologies an asset',
                'Experience with large complex IT Health-related projects.'
              ]
             },
             {
              skillName: 'General Skills',
              skillWeight: 10,
              skillYear:'',
              skillItems:[
                'Strong leadership and people management skills and experience',
                'Exceptional analytical, problem solving and decision-making skills',
                'Demonstrated strong interpersonal, verbal and written communication, and presentation skills',
                'Proven troubleshooting and critical thinking experience',
                'Demonstrated ability to apply strong listening skills to facilitate issue resolution',
                'Effective consulting skills to engage with all stakeholders with proven track record for building strong working relationships',
                'Strong interpersonal, facilitation and negotiation skills with ability to build rapport with stakeholders and drive negotiations to a successful outcome',
                'Excellent customer service skills, including tact and diplomacy to ensure client needs are managed effectively',
                'A motivated, flexible, detail-oriented and creative team player with perseverance, excellent organization and multi-tasking abilities, and a proven track record for meeting strict deadlines.'
              ]
             }
             
           ]


    }
  },
  { 
    id: 'RQ00251', title: 'Systems Testing/QA Specialist - Level 1', client: 'Ministry of Education' ,deadline :'2020-12-11' , isExpanded : false,
    details: { 
           role: 'We are looking for Junior QA with some BA capabilities.',
           musthave: [
            'HPQC/ALM',
            'UAT',
            'SQL',
            'XML'
           ],
           assets:[
            'Public Sector experience'
           ],
           note:[
            'Assignment Type: This position is currently listed as "Hybrid" due to COVID-19 related WFH direction. Once OPS staff are required to return to the office, the resource under this request will be required to work onsite as well. '
           ],
           skillrequried:[
              {
                skillName: 'QA Skills',
                skillWeight: 35,
                skillYear: '',
                skillItems:[
                  'Working knowledge of the entire Software Testing processes',
                  'Demonstrated experience with software testing and testing methodologies',
                  'Demonstrated experience with manual and automated testing',
                  'Demonstrated experience in writing test scripts, test cases and test scenarios, testing',
                  'web applications and reporting defects'

                ]
             },
             {
                skillName: 'Technical Skills',
                skillWeight: 20,
                skillYear: '',
                skillItems:[
                  'Demonstrated experience in using HPQC/ALM and TFS together to document, track, and record testing progress',
                  'Demonstrated experience in SQL, databases, and XML Demonstrated understanding of AODA',
                  'Demonstrated experience testing for WCAB compliance in web applications, experience using screen reader (JAWS) for testing and HiCompliance Sheriff tool',
                  'Experience with desktop tools (Visio, MS Office, MS Project, MS Outlook)',
                  'Demonstrated experience in testing reports and other enhancements to systems',
                  'Demonstrated experience with Test Automation tools in particular Quick Test Pro v10.0, UFT, Compliance Sherriff, Web Accessibility Toolkit, and HP Quality Center'


                ]
             },
             {
              skillName: 'General Skills',
              skillWeight: 10,
              skillYear:'',
              skillItems:[
                'Excellent analytical, problem-solving, and decision-making skills; verbal and written communication skills; interpersonal and negotiation skills',
                'Demonstrated experience working in agile and waterfall environments',
                'Demonstrated experience coordinating multiple projects with competing priorities',
                'Demonstrated capacity to work collaboratively with client organization',
                'A team player with a track record for meeting strict deadlines'
              ]
             },
             {
              skillName: 'Public Sector Experience',
              skillWeight: 5,
              skillYear:'',
              skillItems:[
                'Experience and understanding of public sector project delivery methodologies and standards would be an asset.'
              ]
             }
             
           ]


    }
  }
];
