import {Info} from '../model/info';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class InfoService {
    infos: Info[] = [];
    sendMessage(info : Info){
        this.infos.push(info);
    }
}